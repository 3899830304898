import * as React from "react";
import {MouseEventHandler} from "react";
import {DslObject, DslObjectWidget} from "../utils/dsl_renderer";
import {extraRenderSimpleWidget} from "../utils/yplusplus_dsl_extra";
import {MdArrowBack} from "@react-icons/all-files/md/MdArrowBack";
import {navigate} from "gatsby";
import {Layout} from "./layout";

export const Dot: React.FC<{ className?: string }> = (props) => (
    <span className={`w-1.5 h-1.5 rounded-full bg-black ${props.className}`}/>
)

interface MyButtonProps {
    id?: string;
    href?: string;
    onClick?: MouseEventHandler;
    className: string;
    text: string;
}

export const MyButton = (props: MyButtonProps) => (
    <a
        id={props.id}
        href={props.href}
        onClick={props.onClick}
        className={"border-blue-500 text-blue-500 block leading-4 " + props.className}
        style={{borderBottom: '1px solid #2196f3'}}
    >{props.text}</a>
)

export const IntroContentSubPage = (props: {
    title: string
    dslObject: DslObject
}) => (
    <Layout>
        <div className="flex flex-col w-full">
            <div className="flex flex-row items-center justify-start bg-blue-500 h-14 mb-12 shadow-lg w-full">
                <div className="block px-4 py-2" onClick={() => navigate(-1)}>
                    <MdArrowBack size="24" color="white"/>
                </div>
                <div className="w-2"/>
                <div className="text-normal text-white">{props.title}</div>
            </div>
            <div className="flex flex-col px-8 pb-12">
                <DslObjectWidget
                    dslObject={props.dslObject}
                    extraRenderSimpleWidget={extraRenderSimpleWidget}
                />
            </div>
            <div className="flex flex-row bg-blue-500 text-white py-10 px-10">
                <div className="flex flex-row items-center" onClick={() => navigate(-1)}>
                    <MdArrowBack size="20" color="white"/>
                    <div className="pl-1 text-sm">返回</div>
                </div>
            </div>
        </div>
    </Layout>
)

// interface DownloadAppBuildButtonArg {
//     href: string
//     text: string
// }
//
// export const DownloadApp = (props: {
//     buildButton: (arg: DownloadAppBuildButtonArg) => React.ReactNode
// }) => {
//     if (canDirectlyDownloadInCurrBrowser()) {
//         return <div className="flex flex-row items-center justify-center">
//             {props.buildButton({
//                 href: getDownloadAppUrl(),
//                 text: "下载",
//             })}
//         </div>
//     } else {
//         // 这是腾讯开放平台的"微下载"，QQ微信打开相对有特权一点；同时，苹果打开还会自动跳到app store
//         return <div className="flex flex-row items-center justify-center">
//             {props.buildButton({
//                 href: "https://a.app.qq.com/o/simple.jsp?pkgname=com.cjy.yplusplus",
//                 text: "点击下载",
//             })}
//             <div className="w-4"/>
//             <div>或在浏览器打开</div>
//         </div>
//         // return <div id="hint-jump">
//         //     <div className="h-2"/>
//         //     <p className="item">【下载方式】</p>
//         //     <p className="item">1. 点击右上角"..."按钮</p>
//         //     <p className="item">2. 点击"在浏览器打开"或"浏览器"</p>
//         // </div>
//     }
// }