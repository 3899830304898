import * as React from "react";

export interface DslObject {
    root: DslNodeBaseWidget
}

interface DslNode {
    '@type': string;
}

interface DslNodeBaseWidget extends DslNode {
}

interface DslNodeBaseSpan extends DslNode {
}

interface DslNodeRichTextWidget extends DslNodeBaseWidget {
    text: DslNodeBaseSpan;
}

export interface DslNodeSimpleWidget extends DslNodeBaseWidget {
    name?: string
    attr?: Record<string, any>
    children: DslNodeBaseWidget[]
}

interface DslNodeTextSpan extends DslNodeBaseSpan {
    text?: string
    children?: DslNodeBaseSpan[]
    style?: DslTextStyle
}

interface DslNodeWidgetSpan extends DslNodeBaseSpan {
    child?: DslNodeBaseWidget
    style?: DslTextStyle
}

interface DslTextStyle {
    fontSize?: number
    color?: string
}

export class DslObjectWidget extends React.Component<{
    dslObject: DslObject
    extraRenderSimpleWidget: (node: DslNodeSimpleWidget) => React.ReactNode | null
}> {
    render() {
        return this.buildNode(this.props.dslObject.root);
    }

    buildNode(node?: DslNodeBaseWidget): React.ReactNode {
        if (node == null) {
            return <div/>
        }
        switch (node["@type"]) {
            case 'RichTextWidget':
                return this.buildRichTextWidget(node as DslNodeRichTextWidget);
            case 'SimpleWidget':
                return this.buildSimpleWidget(node as DslNodeSimpleWidget);
            case 'TextSpan':
                return this.buildTextSpan(node);
            case 'WidgetSpan':
                return this.buildWidgetSpan(node);
            default:
                return DslObjectWidget.buildFallback(`when buildNode @type=${node["@type"]}`)
        }
    }

    private buildRichTextWidget(node: DslNodeRichTextWidget) {
        return this.buildNode(node.text)
    }

    private buildSimpleWidget(node: DslNodeSimpleWidget) {
        let extraResult = this.props.extraRenderSimpleWidget(node)
        if (extraResult != null) {
            return extraResult
        }

        // NOTE 仅仅是实现了一部分要用到的...反正网页版可以随时发版
        let children = this.buildChildren(node);
        switch (node.name) {
            case 'Padding':
                return <div style={{
                    paddingLeft: node.attr?.left,
                    paddingRight: node.attr?.right,
                    paddingTop: node.attr?.top,
                    paddingBottom: node.attr?.bottom,
                }}>{children}</div>
            case 'Align':
                let x = node.attr?.['x'] ?? -1.0;
                let y = node.attr?.['y'] ?? -1.0;
                if (Math.abs(x) < 0.0001 && Math.abs(y) < 0.0001) {
                    return <div className="flex flex-col items-center justify-center">{children}</div>
                }
                return <div>{children}</div>
            case 'Container':
                let colorRaw = node.attr?.color;
                return <div style={{
                    backgroundColor: colorRaw != null ? parseARGBColorHexString(colorRaw) : 'inherit',
                    width: node.attr?.width,
                    height: node.attr?.height,
                }}>{children}</div>
            case 'Column':
                return <div className="flex flex-col">{children}</div>
            case 'Row':
                return <div className="flex flex-row">{children}</div>
            case 'DefaultTextStyle':
                return <div style={DslObjectWidget.parseDslTextStyleToCssStyle(node.attr?.style)}>{children}</div>
            default:
                return DslObjectWidget.buildFallback(`when buildSimpleWidget name=${node.name}`)
        }
    }

    private buildTextSpan(node: DslNodeTextSpan) {
        return <span style={{...DslObjectWidget.parseDslTextStyleToCssStyle(node.style), whiteSpace: 'pre-wrap'}}>
            {node.text != null && node.text}
            {this.buildChildren(node)}
        </span>;
    }

    private buildWidgetSpan(node: DslNodeWidgetSpan) {
        return this.buildNode(node.child)
    }

    private buildChildren(node: DslNode & { children?: DslNode[] }) {
        if (node.children?.length === 1) {
            // 就不用额外包裹一层div了
            return this.buildNode(node.children[0])
        }

        return node.children?.map((child, index) => {
            return <div key={index}>{this.buildNode(child)}</div>;
        }) ?? [];
    }

    private static buildFallback(hint: string) {
        return <span>渲染失败({hint})</span>
    }

    private static parseDslTextStyleToCssStyle(style?: DslTextStyle) {
        if (style == null) return {}
        return {
            fontSize: style.fontSize,
            color: style.color != null ? parseARGBColorHexString(style.color) : 'inherit',
        }
    }
}

function parseARGBColorHexString(hex: string) {
    const a = parseInt(hex.slice(0, 2), 16),
        r = parseInt(hex.slice(2, 4), 16),
        g = parseInt(hex.slice(4, 6), 16),
        b = parseInt(hex.slice(6, 8), 16);
    return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")";
}