import * as React from "react";
import {DslNodeSimpleWidget} from "./dsl_renderer";
import {MyVideo} from "../components/my_video";

export function extraRenderSimpleWidget(node: DslNodeSimpleWidget) {
    let name = node.name
    if (name == null) return null

    if (kImageDslSimpleWidgetNames.includes(name)) {
        return <img
            src={`https://static.why01.com/dsl_shot/${name}.png`}
            alt={name}
        />
    }

    if (kVideoDslSimpleWidgetNames.includes(name)) {
        // https://www.gatsbyjs.com/docs/how-to/images-and-media/working-with-video/#hosting-your-own-html5-video-files
        return <MyVideo>
            {/* NOTE 这个"#t=0.1"操作，因为手机QQ/手机微信上，观察到默认是空白的，必须这样才会默认展示第0.1s的视频截图 https://stackoverflow.com/questions/7323053*/}
            <source src={`https://static.why01.com/dsl_shot/${name}.mp4#t=0.1`} type="video/mp4"/>
            <source src={`https://static.why01.com/dsl_shot/${name}.ogg#t=0.1`} type="video/ogg"/>
        </MyVideo>
    }

    return null
}

const kImageDslSimpleWidgetNames = [
    'TutAllFeedPage',
    'TutFriendPage',
    'TutWipeFogChallengeHint',
    'TutEffectArrow',
    'TutEffectRowHandScreen',
    'TutEffectRowMarker',
    'TutChatRow',
    'TutChatDiscussionListPage',
];

const kVideoDslSimpleWidgetNames = [
    'TutWrappedHandScreen',
    'TutWrappedWipeFog',
    'TutWrappedMarker',
    'TutEffectRowWipeFog',
];

