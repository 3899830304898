import * as React from "react";
import {MdPlayArrow} from "@react-icons/all-files/md/MdPlayArrow";

export class MyVideo extends React.Component<{}, {
    playing: boolean
}> {
    private readonly videoRef: React.RefObject<HTMLVideoElement>;

    constructor(props: {}) {
        super(props);
        this.videoRef = React.createRef();
        this.state = {
            playing: false
        }
    }

    handlePlay = () => {
        console.log('handlePlay')
        this.setState({playing: true})
        this.videoRef.current?.play()
    }

    handleVideoEnded = () => {
        this.setState({playing: false})
    }

    render() {
        return <div className="relative">
            <video ref={this.videoRef} onEnded={this.handleVideoEnded}>
                {this.props.children}
            </video>
            {!this.state.playing &&
            <div onClick={this.handlePlay} className="absolute inset-0 z-10 flex justify-center items-center"
                // NOTE 手机QQ不支持8位hex，只好用rgba()形式 https://www.shuzhiduo.com/A/MAzAAmvOz9/
                 style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
                <div className="rounded-full w-12 h-12 bg-blue-500 flex justify-center items-center">
                    <MdPlayArrow size={24} color="white"/>
                </div>
            </div>
            }
        </div>
    }
}
