export const PROJECT_START_DATE = new Date('2019-12-23')

// type PartialMdx = {
//     readonly frontmatter: GatsbyTypes.Maybe<{
//         readonly title: GatsbyTypes.Scalars['String'];
//     }>;
//     readonly fields: GatsbyTypes.Maybe<{
//         readonly mySlug: GatsbyTypes.Maybe<GatsbyTypes.Scalars['String']>;
//         readonly date: GatsbyTypes.Maybe<GatsbyTypes.Scalars['Date']>;
//     }>;
// }
//
// function computeTitleWhenModeJournal(date: string) {
//     const MILLISECOND_PER_DAY = 1000 * 60 * 60 * 24
//
//     const diffDays = Math.round((new Date(date).getTime() - PROJECT_START_WEEKEND.getTime()) / MILLISECOND_PER_DAY)
//     if (diffDays % 7 != 0) throw Error(`computeTitleWhenModeJournal unexpected diffDays=${diffDays}`)
//     const diffWeeks = Math.round(diffDays / 7)
//
//     return `第${diffWeeks}周`
// }
//
// export function computeEffectiveTitle(mdx: PartialMdx): string {
//     let frontmatter = mdx.frontmatter;
//     if (frontmatter == null) return "FALLBACK_TITLE"
//
//     if (frontmatter.modeJournal === true && frontmatter.title.length == 0) {
//         return computeTitleWhenModeJournal(mdx.fields!.date!)
//     }
//
//     return frontmatter.title
// }