import React from "react"
import {graphql, PageProps} from "gatsby"
import {Layout} from "../components/layout";
import {MDXRenderer} from "gatsby-plugin-mdx";
// @ts-ignore
import {MDXProvider} from "@mdx-js/react";
import {Beian} from "../components/beian";
import {PROJECT_START_DATE} from "../utils/blog/blog";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {Dot} from "../components/misc";
import {FaGithub} from "@react-icons/all-files/fa/FaGithub";
import {FaStackOverflow} from "@react-icons/all-files/fa/FaStackOverflow";
import {MdArrowUpward} from "@react-icons/all-files/md/MdArrowUpward";
import _ from "lodash";

const MS_PER_DAY = 1000 * 60 * 60 * 24

const IndexNormalPage: React.FC<PageProps<GatsbyTypes.BlogListQueryQuery>> = ({data, location}) => {
    const rawPosts = data.allMdx.nodes
    const rawImages = data.allImagesPreprocessedIndexYaml.nodes

    const imageOfBaseMap = _.keyBy(rawImages, (o) => o.imagePath!.base)

    const boundImageBases = rawPosts
        .map((mdx) => mdx.frontmatter?.bindImage)
        .filter((bindImage) => bindImage != null && bindImage.length > 0)
    const nonBoundImageBases = rawImages
        .map((image) => image.imagePath!.base)
        .filter((imageBase) => !boundImageBases.includes(imageBase))

    const orderedImageBases: (string | null)[] = _.times(rawImages.length, _.constant(null))
    for (let i = 0; i < rawPosts.length; ++i) {
        const bindImageBase = rawPosts[i].frontmatter?.bindImage
        if (bindImageBase == null || bindImageBase.length === 0) continue
        orderedImageBases[i] = bindImageBase
    }
    fillArrayNullValues(orderedImageBases, nonBoundImageBases)

    const postComponents = rawPosts.map((mdx) =>
        <SinglePost key={mdx.id} mdx={mdx}/>)
    const imageComponents = orderedImageBases.map((imageBase) =>
        <SingleImage key={imageBase} image={imageOfBaseMap[imageBase!]}/>)

    return (
        <Layout>
            <MDXProvider components={mdxComponents}>
                <HeadContent/>

                <div className="flex flex-col items-left p-0 m-0 flex-1 w-full break-all mt-8">
                    {interleaveArray(postComponents, imageComponents)}
                </div>

                <div className="mt-8"/>
                <div className="flex flex-col items-center">
                    <Beian/>
                </div>
            </MDXProvider>
        </Layout>
    )
}

function fillArrayNullValues(dst: (string | null) [], values: string[]) {
    for (let i = 0, j = 0; i < dst.length; ++i) {
        if (dst[i] != null) continue
        dst[i] = values[j++]
    }
}

function interleaveArray<T>(a: T[], b: T[]) {
    const ans = []
    let aIndex = 0, bIndex = 0
    while (ans.length < a.length + b.length) {
        if (aIndex < a.length) ans.push(a[aIndex++])
        if (bIndex < b.length) ans.push(b[bIndex++])
    }
    return ans
}

const HeadContent = (props: {}) => {
    return <div>
        <div className="flex flex-col items-start bg-blue-500 text-white w-full">
            <div className="flex flex-row mx-6 items-center mt-3 mb-3">
                {/*<StaticImage className="w-4 h-4" alt="icon" src="../../images/icon.png"/>*/}
                {/*<div className="w-2"/>*/}
                <div className="text-lg">陈靖一的博客</div>
            </div>
        </div>
        <div className="flex flex-col pt-6 px-6 pb-3 bg-blue-50 text-sm">
            <div className="flex flex-row items-center pb-3">
                <p>
                    <Dot className="inline-block mr-2 my-0.5"/>
                    <span>我的项目：</span>
                    {/*<StaticImage className="inline-block ml-1 mr-1 w-4 h-4" alt="icon" src="../../images/icon.png"/>*/}
                    {/* #2771 不对外展示 */}
                    {/*<Link to="/intro" className="text-blue-700 underline">Why数理</Link>*/}
                    <span>Why数理</span>
                    {/*<span className="text-xs">（不对外开放，搜索出的全是无关内容）</span>*/}
                </p>
                <DownloadButton/>
            </div>
            {/*<div className="text-xs pb-3 pl-3.5">(不对外开放，搜索只会得到无关内容)</div>*/}
            <div className="flex flex-row items-center pb-3">
                <Dot className="mr-2"/>
                <a href="https://github.com/fzyzcjy" className="">
                    <FaGithub className="text-gray-500 mr-0.5" size={20}/>
                </a>
                开源
                <p className="pl-1">&gt;5000star</p>
                ，
                <a href="https://stackoverflow.com/users/4619958/ch271828n" className="">
                    <FaStackOverflow className="text-gray-500 mr-0.5" size={20}/>
                </a>
                助人
                <p className="pl-1">&gt;15000rep</p>
            </div>
            <div className="flex flex-row items-center pb-2">
                <Dot className="mr-2"/>
                13.5年编程经验，40万行代码
            </div>
        </div>
    </div>
}

const MDXParagraph = (props: any) => (
    <p className="mb-2" {...props} />
)

// https://www.gatsbyjs.com/plugins/gatsby-plugin-mdx/#mdxprovider
const mdxComponents = {p: MDXParagraph}

const SinglePost: React.FC<{ mdx: any }> = (props) => {
    const numDay = (new Date(props.mdx.fields!.date!).getTime() - PROJECT_START_DATE.getTime()) / MS_PER_DAY

    const datePrefix = `第${numDay}天`

    return <div className="px-6 mb-8 leading-7 text-sm">
        <div className="flex flex-row pb-3 justify-between">
            <div className="flex flex-row items-center">
                {props.mdx.frontmatter.pinned === true && <PinnedBadge/>}
                <p className="font-bold text-xl">
                    {props.mdx.frontmatter.title}
                </p>
            </div>
            {props.mdx.frontmatter.disableTitleDatePrefix !== true &&
                <p className="text-gray-500">{datePrefix}</p>}
        </div>
        <MDXRenderer>{props.mdx.body}</MDXRenderer>
    </div>
}

const PinnedBadge: React.FC = (props) => <div
    className="inline-block bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-1.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
    置顶
</div>

// const DEBUG_SHOW_IMAGE_NAME = true // for debug
const DEBUG_SHOW_IMAGE_NAME = false

const SingleImage: React.FC<{ image: any }> = (props) => {
    const gatsbyImageData = getImage(props.image.imagePath.childrenImageSharp[0])!

    return <div className="mb-8">
        <GatsbyImage alt={"图片"} image={gatsbyImageData}/>
        <div className="flex flex-col items-center px-6">
            <p className="mt-1 text-sm">
                <MdArrowUpward className="inline-block mr-2 align-baseline" size={12}/>
                <span>{props.image.title}</span>
                <span className="text-xs text-gray-500 ml-2">({props.image.subtitle})</span>
                {DEBUG_SHOW_IMAGE_NAME &&
                    <span className="text-xs text-gray-500"> ({props.image.imagePath.base})</span>}
            </p>
        </div>
    </div>
}

const DownloadButton = () => {
    // #10760
    return <span className="text-[0.6rem] pl-1">(安卓/苹果应用市场搜索下载)</span>

    // let downloadAppInfo = useDownloadAppInfo()
    //
    // return <MyButton
    //     className="ml-2"
    //     href={downloadAppInfo.url}
    //     text={downloadAppInfo.url ? "点击下载" : "[在浏览器打开本页以下载]"}
    // />
}

export default IndexNormalPage

// noinspection JSUnusedGlobalSymbols
export const blogListQuery = graphql`
    query BlogListQuery {
        allMdx(
            sort: { fields: [fields___mySlug], order: DESC }
        ) {
            nodes {
                id
                fields {
                    date
                }
                body
                frontmatter {
                    title
                    disableTitleDatePrefix
                    pinned
                    bindImage
                }
            }
        }
        allImagesPreprocessedIndexYaml {
            nodes {
                id
                title
                subtitle
                imagePath {
                    base
                    childrenImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    }
`

